.itemDetails {
    display: flex;
    gap: 32px;
    width: 100%;
}

.image {
    flex-basis: 40%;
    width: 100%;
}

.productDetails {
    flex-grow: 1;
}

.orderForm {
    padding-top: 16px;
}

.packing {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 1024px) {
    .itemDetails {
        flex-wrap: wrap;
    }

    .image {
        flex-grow: 1;
    }
}