.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    max-width: 100%;
}

.carouselContainer {
    position: relative;
    width: 100%;
}

.control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.leftButtonContainer {
    composes: control;
    left: 8px;
}

.rightButtonContainer {
    composes: control;
    right: 8px;
}

.carousel {
    display: flex;
    width: 100%;
    overflow: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;

    position: relative;
}

.item {
    flex-basis: 100%;
    flex-shrink: 0;
    scroll-snap-align: start;
    scroll-snap-stop: normal;
}