.container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 8px 32px;
}

.buttons {
    display: flex;
    align-items: center;
    gap: 16px;
}

.logo {
    display: flex;
    align-items: center;
    gap: 12px;
}

@media (max-width: 600px) {
    .logoText {
        display: none;
    }
}
