.page {
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    height: 90vh;
}

.logo {
    display: flex;
    width: 100%;
    justify-content: center;
}