.orderRow {
    display: flex;
    gap: 32px;
    align-items: center;
    padding: 16px;
}

.image {
    border-radius: 8px;
    overflow: hidden;
    flex-basis: 160px;
}

.description {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}

.itemDescription {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

@media (max-width: 425px) {
    .orderRow {
        flex-wrap: wrap;
    }
}