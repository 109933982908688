.controlRow {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
}

.controls {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.modal {
    position: absolute;
    top: 50%;
    left:  50%;
    transform: translate(-50%, -50%);
    background-color: white;
    width: 50%;
    padding: 24px;
}

.btnContent {
    display: flex;
    gap: 8px;
}