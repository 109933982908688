.small {
    width: 80px;
}

.medium {
    width: 128px;
}

.large {
    width: 300px;
}