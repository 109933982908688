.image {
    width: 100%;
    aspect-ratio: 4 / 3;
}

.fallback {
    composes: image;
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}