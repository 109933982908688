.content {
    display: flex;
    gap: 32px;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.cart {
    composes: column;
    flex-grow: 1;
}

.cartDetails {
    display: flex;
    gap: 16px;
    align-items: center;
}

.summary {
    composes: column;
    flex-basis: 30%;
}

.lineItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .content {
        flex-wrap: wrap;
    }

    .summary {
        flex-grow: 1;
    }
}