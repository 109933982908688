.itemList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}

 .item {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
    
.filters {
    display: flex;
    align-items: center;
    gap: 24px;
    max-width: 100%;
}

.productsPerPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.qtyInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
}

.selectFilter {
    min-width: 180px;
}

.search {
    flex-grow: 1;
}

.loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    min-height: 320px;
}

@media (max-width: 800px) {
    .itemList {
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }

    .filters {
        flex-wrap: wrap;
    }
}