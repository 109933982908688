.card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
}

.image {
    border-radius: 8px;
    overflow: hidden;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.packing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}